import React from 'react'
import Header from './header'
import '../styles/layout.css'
import { useStaticQuery, graphql } from "gatsby"
import { Box } from '@mui/material';

const drawerWidth = 190
const mobileDrawerWidth = 380;

const Layout = ({children, hideHeader}) => {
  const query = useStaticQuery(graphql`
    query CollectionsQuery {
      collections: allSanityCollection(sort: {title: ASC}) {
        nodes {
          title
          slug {
            current
          }
        }
      }
    }
  `)

  const collections = (query && query.collections.nodes) || []
  const [isMobile, setIsMobile] = React.useState(false);
  const windowGlobal = typeof window !== 'undefined' && window;
  const pathname = windowGlobal ? windowGlobal.location.pathname : null;

  React.useEffect(() => {
    setIsMobile(window.screen.width < 885)
    window.addEventListener("resize", () =>
        setIsMobile(window.screen.width < 885)
    )
  }, isMobile)

  return (
  <>
    {
      hideHeader == 0 &&
      <Header isMobile={isMobile}/>
    }
    <Box
      component="main"
      sx={{ 
        flexGrow: 1, 
        p: isMobile ? 0 : 3, 
        borderWidth: 0, 
        width: isMobile ? '100%' : `calc(100% - ${drawerWidth}px)`,
        float: !isMobile && 'right'
       }}
    >
      {children}
    </Box>
  </>
)}

export default Layout
