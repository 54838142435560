import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../components/layout';
import { Button, ListItemIcon, ListItemText, Paper, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import * as styles from '../components/layout.module.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import { Dimensions } from "react-native";

const drawerWidth = 400;
const mobileDrawerWidth = 350;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  ...theme.applyStyles('dark', {
    backgroundColor: 'transparent',
  }),
}));

function Header(props) {
  // const { window } = props;
  const query = useStaticQuery(graphql`
    query HeaderQuery {
      site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
        title
        description
        address
        phone
        mobile
        email
        occupation
        facebook
        keywords
        shop_images{
          asset {
            url
          }
        }
        logo {
          asset {
            url
          }
        }
      }
      allSanityCollection(sort: {priority: ASC}){
        nodes {
          _key
          id
          title
          slug {
            current
          }
          products {
            title
            slug{
              current
            }
          }
        }
      }
    }
  `)

  const site = (query || {}).site
  const collections = (query && query.allSanityCollection || {}).nodes || []

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   )
  // }

  // const [products, setProducts] = React.useState(props.products)
  // const [isMobile, setIsMobile] = React.useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [isScreenResolutionSmall, setIsScreenResolutionSmall] = React.useState(false);
  // const [collection, setCollection] = React.useState(collections.filter(collection=>collection.slug.current===window.location.pathname.replace("/", '').replace("/", '')))


  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleCopy = async (content) => {
    try {
        await navigator.clipboard.writeText(content);
        console.log('Copied to clipboard:', content);
    } catch (error) {
        console.error('Unable to copy to clipboard:', error);
    }
  };

  const windowGlobal = typeof window !== 'undefined' && window;
  const pathname = windowGlobal ? windowGlobal.location.pathname : null;

  const drawer = (
    <div style={{fontFamily: 'inherit', backgroundColor: '#000', color: '#fff'}}>
        <Typography variant="h5" noWrap style={{maxWidth: '60%', margin: 'auto', textAlign: 'center',  maxHeight: 200, fontFamily: "inherit"}}>
                <a href="/"><img src={site && site.logo.asset.url} style={{width: '70%'}}/></a>
          </Typography>
        <Typography component="div" variant='body2' style={{textAlign: 'center', width: !props.isMobile && '80%', margin: !props.isMobile && 'auto', padding: 4, fontFamily: "inherit"}}>
            {site && site && site.occupation}
        </Typography>
        { props.isMobile &&
            <Toolbar>
                <Tooltip title="Κλείσιμο μενού" titleTypographyProps={{fontFamily: 'inherit'}}>
                    <IconButton
                    component={Button}
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    sx={{ fontFamily: 'inherit', border: '0px solid #fff', borderRadius: 3, backgroundColor: '#303030', height: 50, m: '10px auto', ...(mobileOpen && { display: 'block' }) }}
                    >
                      <KeyboardReturnRoundedIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        }
        <Divider style={{backgroundColor: '#363636'}}/>
        {/* <Toolbar/> */}
        <List>
            <ListItem key="home" disablePadding>
                <ListItemButton href={`/`} style={{textTransform: 'uppercase', textAlign: 'center'}}>
                <ListItemText 
                    primary={`Αρχική`} 
                    primaryTypographyProps={{
                    fontSize: 16,
                    fontFamily: 'inherit'
                    }}
                    style={{color: pathname && pathname === `/` ? 'orange' : 'white'}}
                />
                </ListItemButton>
            </ListItem>
            {collections.map((node) => (
                <ListItem key={node.id} disablePadding>
                    <ListItemButton href={`/${node.slug.current}`} style={{textTransform: 'uppercase', textAlign: 'center'}}>
                        <ListItemText 
                            primary={node.title} 
                            primaryTypographyProps={{
                                fontSize: 16,
                                fontFamily: 'inherit',
                                color: pathname && pathname.indexOf(node.slug.current) > -1 ? 'orange' : 'white'
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            ))}
            <ListItem key="contact" disablePadding>
                <ListItemButton href={`/contact/`} style={{textTransform: 'uppercase', textAlign: 'center'}}>
                <ListItemText 
                    primary={`Επικοινωνία`} 
                    primaryTypographyProps={{
                    fontSize: 16,
                    fontFamily: 'inherit'
                    }}
                    style={{color: pathname && pathname.indexOf(`/contact/`) > -1 ? 'orange' : 'white'}}
                />
                </ListItemButton>
            </ListItem> 
        </List>

        
        <div className={props.isMobile ? styles.footerInMobile : styles.footerWrapper}>
            { !props.isMobile && (
                <List style={{margin: 'auto', width: '100%'}}>
                    <ListItem disablePadding style={{width: 300, margin: 'auto'}}>
                      <Tooltip title="Ακολουθήστε μας" titleTypographyProps={{fontFamily: 'inherit'}}>
                          <Button
                              onClick={() => window.open(site && site.facebook)} 
                              style={{margin: 'auto', textTransform: 'lowercase', fontFamily: 'inherit'}} color="inherit"><FacebookIcon fontSize='large'/></Button>
                      </Tooltip>
                    </ListItem>
                    <ListItem style={{margin: 'auto'}}>
                        <Grid container columnSpacing={1}>
                            <Grid size={6}>
                              <Item>
                                <ListItemButton style={{textAlign: 'center'}}>
                                    <ListItemText primary={site && site.phone} primaryTypographyProps={{fontFamily: 'inherit'}}/>
                                    <Tooltip title="Αντιγραφή"><IconButton onClick={(e) => handleCopy(`${site.phone}`)} style={{color: 'white'}}><ContentCopyIcon fontSize='small'/></IconButton></Tooltip>
                                </ListItemButton>
                              </Item>
                            </Grid>
                            <Grid size={6}>
                              <Item>
                                <ListItemButton style={{textAlign: 'center'}}>
                                    <ListItemText primary={site && site.mobile} primaryTypographyProps={{fontFamily: 'inherit'}} />
                                    <Tooltip title="Αντιγραφή"><IconButton onClick={(e) => handleCopy(`${site.mobile}`)} style={{color: 'white'}}><ContentCopyIcon fontSize='small'/></IconButton></Tooltip>
                                </ListItemButton>
                              </Item>
                            </Grid>
                        </Grid>
                    </ListItem>        
                </List>
                )
            }
            <Divider style={{backgroundColor: '#363636'}}/>
            <div className={props.isMobile ? '' : styles.footer} style={{fontFamily: 'inherit', textAlign: 'center', padding: 10}}>
              {
                  props.isMobile &&
                  <Grid container columnSpacing={2} style={{marginBottom: '10%', marginTop: 10}}>
                      <Grid size={12}>
                          <Tooltip title="Ακολουθήστε μας" titleTypographyProps={{fontFamily: 'inherit'}}>
                              <Button
                                  onClick={() => window.open(site && site.facebook)} 
                                  style={{margin: 'auto', textTransform: 'lowercase', fontFamily: 'inherit'}} color="inherit">
                                  <FacebookIcon fontSize='large'/>
                              </Button>
                          </Tooltip>
                      </Grid>
                      <Grid size={6}>{site && site.phone}<Tooltip title="Αντιγραφή"><IconButton onClick={(e) => handleCopy(`${site && site.phone}`)} style={{color: 'white'}}><ContentCopyIcon fontSize='small'/></IconButton></Tooltip></Grid>
                            <Grid size={6}>{site && site.mobile}<Tooltip title="Αντιγραφή"><IconButton onClick={(e) => handleCopy(`${site && site.mobile}`)} style={{color: 'white'}}><ContentCopyIcon fontSize='small'/></IconButton></Tooltip></Grid>
                  </Grid>
              }
              <h7 style={{textAlign: 'center', margin: 'auto'}}>© {new Date().getFullYear()} JP Inox Systems</h7><br/>
          </div>
        </div>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {
        props.isMobile && 
        <AppBar
          position="relative"
          sx={{
            width: { sm: `calc(100%)` },
            ml: { sm: 0 },
          }}
          style={{boxShadow: 'none', backgroundColor: props.isMobile ? '#000' : 'transparent'}}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'block' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" style={{textAlign: 'center', width: '100%'}}>
              <a href="/"><img src={site && site.logo.asset.url} style={{width: '40%'}}/></a>
            </Typography>
          </Toolbar>
        </AppBar>
      }

      <Box
        component="nav"
        sx={{ width: { sm: 'auto' }, flexShrink: { sm: 0 } }}
        aria-label="folders"
        style={{borderRight: 0}}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {
          props.isMobile ? (
            <Drawer
              // container={container}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: mobileOpen && 'block', md: 'none' },
                '& .MuiDrawer-paper': {backgroundColor: '#000', boxSizing: 'border-box', width: '100%' },
              }}
            >
              {drawer}
            </Drawer>
          ) : (
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': {backgroundColor: '#000',  boxSizing: 'border-box', width: drawerWidth },
              }}
              open
            >
              {drawer}
            </Drawer>
          )}
      </Box>      
    </Box>
  );
}

export default Header;