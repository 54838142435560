/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import ReactDOM from "react-dom/client";
import Layout from "./src/components/layout";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

// Replace this by your own page wrapper:
export const wrapPageElement = ({ element, props }) => {
  return <Layout hideHeader={0} {...props}>{element}</Layout>
}